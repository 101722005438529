import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import Link from 'next/link';
import auth from '../lib/auth';
import Head from 'next/head';
import useWishlistRedirect from '../components/hooks/useWishlistRedirect';
import ScrollContainer from 'react-indiana-drag-scroll';

import { Typewriter } from 'react-simple-typewriter';

import styles from './index.module.scss';
import DreamButton from 'components/globals/DreamButton';
import PublicFooter from 'components/globals/PublicFooter';

interface HomeProps {
  idTokenExpired: boolean;
}

const anyBrandList = [
  'any brand',
  'Prada',
  'Gucci',
  'Fendi',
];

interface LeaderboardWinner {
  name: string;
  username: string;
  profilePic: string;
  rank: number;
}

const Home: React.FC<HomeProps> = ({ idTokenExpired }) => {
  const router = useRouter();
  const [, forceUpdate] = useState<Record<string, string>>({});

  const [lastMonthWinners, setLastMonthWinners] = useState<LeaderboardWinner[]>([]);

  // Handle error redirects
  useEffect(() => {
    if (idTokenExpired) {
      auth.logout();
    }
  }, [idTokenExpired]);

  /* Force update with scroll for sticky header */
  useEffect(() => {
    let canScroll = true;
    const handleScroll = () => {
      if (canScroll) {
        forceUpdate({});
        canScroll = false;
        setTimeout(() => {
          forceUpdate({});
          canScroll = true;
        }, 250);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (router.query.signup_code) {
      const signupCode: string = typeof router.query.signup_code === 'string' ? router.query.signup_code : router.query.signup_code[0];
      localStorage.setItem('affiliate', signupCode);
      router.replace('/');
    }
  }, [router]);

  useWishlistRedirect(router);

  useEffect(() => {
    const fetchLastMonthWinners = async () => {
      try {
        const response = await fetch('/api/lastMonthWinners');
        if (response.ok) {
          const data = await response.json();
          setLastMonthWinners(data);
        } else {
          console.error('Failed to fetch last month winners');
        }
      } catch (error) {
        console.error('Error fetching last month winners:', error);
      }
    };

    fetchLastMonthWinners();
  }, []);

  return (
    <div className={cx('absolute top-0 left-0 right-0', styles.container)}>
      <Head>
        <title>Dreamgifts: let your fans buy you gifts from any brand</title>
      </Head>
      <div className={cx(styles.header, typeof window !== 'undefined' && window.scrollY !== 0 && styles.scrolling)}>
        <img src="/dreamgifts-logo-ng.png" className={styles.headerLogo} />
        <div className={styles.headerButtons}>
          <Link href="/leaderboard">
            <button className="rounded-full border px-5 py-1 lg:py-2 leading-tight border-black z-50">Leaderboard</button>
          </Link>
          <Link href="/login">
            {
              typeof window !== 'undefined' && window.scrollY !== 0 ?
                <DreamButton contentClassName="text-sm font-medium">Sign up</DreamButton> :
                <button className="flex items-center rounded-full border px-5 py-1 lg:py-2 leading-tight border-black z-50">Login <img src="/homepage/arrow_up_right.svg" className="inline-block ml-2" /></button>
            }
          </Link>
        </div>
      </div>
      <div className={styles.heroOuter}>
        <div className={styles.hero}>
          <div className={styles.heroContent}>
            <div className="h-full flex flex-col justify-center z-10 transform transforn-none relative">
              <h1 className={styles.pageTitle}>Let your fans buy you<br/>
                gifts from <div className={styles.anyBrand}>
                  &nbsp;<Typewriter words={anyBrandList} loop={0} delaySpeed={2000} typeSpeed={90} deleteSpeed={80} />
                </div>
              </h1>

              <div className="mt-6 z-50 flex flex-col lg:flex-row mb-12 md:mb-16 lg:mb-0 transform transform-none">
                <Link href="/login">
                  <DreamButton className="flex items-center lg:mr-3 mb-3 md:mb-5 lg:mb-0 justify-center">Sign up</DreamButton>
                </Link>
                <a href="https://dreamgifts.co/Artemisfit" target="_blank" rel="noopener noreferrer" className={styles.grayButton}>See Example Wishlist</a>
              </div>
            </div>
            <div className={styles.heroImage}></div>
          </div>
        </div>
      </div>

    
      <div className={styles.features}>
        <div className={styles.optionality}>
          <h2 className={styles.featuresTitle}>
            <b>Optionality</b> &ndash; Pick any product from any website on the internet, and add it to your wishlist.
          </h2>
        </div>
        <div className={styles.delight}>
          <h2 className={styles.featuresTitle}><b>Lower Fees</b> &ndash; Accept cash gifts from your fans for only a 10% processing fee</h2>
        </div>
        <div className={styles.security}>
          <h2 className={styles.featuresTitle}><b>Security</b> &ndash; We ensure your address is never exposed to fans</h2>
        </div>
      </div>

      <ScrollContainer className={styles.featuredListsTrack} vertical={false}>
        <h1 className={styles.featuredListsTitle}>Last Month&apos;s Top Wishlists</h1>

        <div className={styles.featuredLists}>
          {lastMonthWinners.map((winner, index) => (
            <div key={winner.username}>
              <div className="overflow-hidden" style={{ width: '380px', height: '500px', borderRadius: '32px' }}>
                <img src={winner.profilePic} alt={winner.name} className="w-full h-full object-cover" />
               
              </div>
              <h2 className={styles.featuredListTitle}>{winner.name}</h2>
              <a href={`/${winner.username}`} target="_blank" className="inline-flex items-center rounded-full border px-5 py-2 leading-tight border-black z-50" rel="noreferrer">
                Browse Wishlist
                <img src="/homepage/arrow_up_right.svg" className="inline-block ml-2 w-3 h-3" style={{ position: 'relative', top: '-1px' }} />
              </a>
            </div>
          ))}
        </div>
      </ScrollContainer>   

      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <h1 className={styles.footerTitle}>Ready to start <br /> getting <b>better gifts?</b></h1>
          <Link href="/login">
            <button className={styles.gradientButton}>Create Wishlist</button>
          </Link>
          <p className={styles.footerFree}>It&rsquo;s FREE!</p>
        </div>
        <PublicFooter className={cx(styles.endMatter, 'pt-8')} />
      </div>
    </div>
  );

}

export default Home;